var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hover:bg-f9 px-20 py-10",class:{ 'bg-f9': _vm.mode === 'update' }},[(_vm.mode === 'display')?_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("解决问题：")]),_c('div',[_vm._v(_vm._s(_vm.model.Desc))])]),_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("大事记类型：")]),_c('div',[_vm._v(_vm._s(_vm.model.AspectsName))])]),_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("参与部门：")]),_c('div',[_vm._v(_vm._s(_vm.model.departmentsName))])]),_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("时间段：")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.model.StartName))]),_c('span',[_vm._v(" - ")]),_c('span',[_vm._v(_vm._s(_vm.model.EndName))])])]),_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("阶段性成果：")]),_c('div',[_vm._v(_vm._s(_vm.model.Result))])]),_c('div',{staticClass:"mb-20"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("合作企业：")]),_c('div',[_vm._v(_vm._s(_vm.model.PartnersName))])])]):(_vm.mode === 'update')?_c('div',[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"labelAlign":"left","colon":false,"labelCol":{ span: 8 },"wrapperCol":{ span: 15 }}},[_c('gf-form-item',{attrs:{"rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        },"prop":"Name","label":"项目名称"}},[_c('gf-re-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.model.Name),callback:function ($$v) {_vm.$set(_vm.model, "Name", $$v)},expression:"model.Name"}})],1),_c('gf-form-item',{attrs:{"rules":[
          {
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          },
          {
            max: 300,
            message: '超出字符限制',
            trigger: ['change', 'blur'],
          } ],"prop":"Desc","label":"解决问题"}},[_c('gf-re-textarea',{attrs:{"placeholder":"请详细描述该项目解决了什么问题","rows":5,"max":300},model:{value:(_vm.model.Desc),callback:function ($$v) {_vm.$set(_vm.model, "Desc", $$v)},expression:"model.Desc"}})],1),_c('gf-form-item',{attrs:{"rules":{
          required: true,
          message: '请选择',
          trigger: ['change', 'blur'],
        },"prop":"Aspects","label":"大事记类型"}},[_c('gf-custom-select',{attrs:{"placeholder":"请选择","flag":"DigitalAspect","custom":true},model:{value:(_vm.model.Aspects),callback:function ($$v) {_vm.$set(_vm.model, "Aspects", $$v)},expression:"model.Aspects"}})],1),_c('gf-form-item',{attrs:{"rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        },"prop":"Departments","label":"参与部门"}},[_c('gf-select',{attrs:{"mode":"tags","placeholder":"请输入"},model:{value:(_vm.model.Departments),callback:function ($$v) {_vm.$set(_vm.model, "Departments", $$v)},expression:"model.Departments"}})],1),_c('gf-form-item',{attrs:{"rules":{
          required: true,
          validator: _vm.validator,
        },"prop":"Time","label":"时间段"}},[_c('a-row',[_c('a-col',{attrs:{"span":11}},[_c('gf-form-item',{attrs:{"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },"prop":"Start"}},[_c('a-month-picker',{attrs:{"valueFormat":"x"},model:{value:(_vm.model.Start),callback:function ($$v) {_vm.$set(_vm.model, "Start", $$v)},expression:"model.Start"}})],1)],1),_c('a-col',{attrs:{"span":11,"offset":2}},[_c('gf-form-item',{attrs:{"rules":{
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },"prop":"End"}},[_c('a-month-picker',{attrs:{"valueFormat":"x"},model:{value:(_vm.model.End),callback:function ($$v) {_vm.$set(_vm.model, "End", $$v)},expression:"model.End"}})],1)],1)],1)],1),_c('gf-form-item',{attrs:{"rules":[
          {
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          },
          {
            max: 300,
            message: '超出字符限制',
            trigger: ['change', 'blur'],
          } ],"prop":"Result","label":"阶段性成果"}},[_c('gf-re-textarea',{attrs:{"placeholder":"请详细描述该项目取得的阶段性成果","rows":5,"max":300},model:{value:(_vm.model.Result),callback:function ($$v) {_vm.$set(_vm.model, "Result", $$v)},expression:"model.Result"}})],1),_c('gf-form-item',{attrs:{"rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        },"prop":"Partners","label":"合作企业"}},[_c('a-select',{attrs:{"mode":"tags","filterOption":false,"showSearch":true},on:{"search":_vm.getCompanyList,"popupScroll":_vm.popupScroll},model:{value:(_vm.model.Partners),callback:function ($$v) {_vm.$set(_vm.model, "Partners", $$v)},expression:"model.Partners"}},_vm._l((_vm.company),function(item){return _c('a-select-option',{key:item,attrs:{"title":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1),_c('div',{staticClass:"text-right"},[_vm._t("update",null,null,{ validate: _vm.validate })],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }