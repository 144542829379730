import request from '@/utils/request1';

// 获取大事记列表
export function getEvents(id, params) {
    return request.get(`/company/${id}/events`, { params })
}

// 创建大事记
export function createEvent(data) {
    return request.post('/events', data)
}

// 删除大事记
export function delEvent(id) {
    return request.delete(`/events/${id}`)
}

// 获取大事记详情
export function getEvent(id) {
    return request.get(`/events/${id}`)
}

// 更新大事记
export function updateEvent(id, data) {
    return request.put(`/events/${id}`, data)
}

// 获取当前大事记详情
export function getEventDraft(id) {
    return request.get(`/events/${id}/draft`)
}

// 提交大事记
export function submitEvent(id) {
    return request.post(`/events/${id}/submit`)
}

// 设置大事记可见性
export function visibleEvent() {
    return request.post(`/events/${id}/visible`)
}

// 获取大事记审核列表
export function getOpCompanyEvents(id, params) {
    return request.get(`/op/company/${id}/events`, { params })
}

// 获取企业大事记审核列表
export function getOpEvents(params) {
    return request.get(`/op/events`, { params })
}

// 获取大事记详情
export function getOpEvent(id) {
    return request.get(`/op/events/${id}`)
}

// 更新大事记
export function updateOpEvent(id, data) {
    return request.put(`/op/events/${id}`, data)
}

// 审核大事记
export function evaluateOpEvent(id, data) {
    return request.post(`/op/events/${id}/audit`, data)
}

// 获取当前企业大事记
export function getCompanyEvents(params) {
    return request.get(`/user/company/events`, { params })
}