<template>
  <div class="py-20 bg-white">
    <a-collapse
      :activeKey="activeKey"
      :bordered="false"
      style="background-color: transparent"
    >
      <a-collapse-panel
        style="border: none"
        class="relative"
        v-for="item in products"
        :key="item.ID"
        :showArrow="false"
        :disabled="true"
      >
        <div
          class="absolute w-full h-full top-0 left-0 z-10"
          v-if="
            (item.AuditStatus !== 1 && isManager) ||
            (item.AuditStatus === 1 && !isManager)
          "
        ></div>
        <Header slot="header">
          <div class="flex items-center">
            <span class="mr-10">{{ item.Name || item.Title }}</span>
            <CompanyStatus :AuditStatus="item.AuditStatus" />
          </div>
        </Header>
        <a-space slot="extra">
          <ExtraSubmit
            v-if="item.ID !== '0' && item.AuditStatus === 0"
            @click.native="submitProduct(item)"
          />
          <ExtraUpdate
            v-if="
              item.ID !== '0' &&
              ((item.AuditStatus === 1 && isManager) ||
                (item.AuditStatus !== 1 && !isManager))
            "
            @click.native="updateProduct(item)"
          />
          <ExtraDel
            v-if="item.ID !== '0' && !isManager && item.AuditStatus !== 1"
            @click.native="delProduct(item)"
          />
          <Extra
            v-else-if="item.ID === '0'"
            @click.native="updateProduct(item)"
          />
        </a-space>
        <!-- display 和 update -->
        <MainNode :mode="item.mode" :dataSource="item">
          <a-space slot="update" slot-scope="{ validate }">
            <a-button @click="cancelProduct(item)" type="primary" ghost
              >取消</a-button
            >
            <a-button @click="saveProduct(validate)" type="primary"
              >保存</a-button
            >
          </a-space>
        </MainNode>
        <div class="text-right px-20">
          <a-button
            v-if="
              isManager && origin.AuditStatus === 1 && item.mode === 'display'
            "
            type="primary"
            @click="modal = true"
            >审核</a-button
          >
        </div>
      </a-collapse-panel>
    </a-collapse>

    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluateRecoginze"
    >
      <a-form-model :model="evaluate">
        <a-form-model-item>
          <a-select v-model="evaluate.AuditStatus">
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="evaluate.AuditStatus === 3">
          <gf-textarea
            :required="true"
            prop="Feedback"
            :max="300"
            :rows="5"
            v-model="evaluate.Feedback"
          ></gf-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Extra from "./components/extra.vue";
import ExtraUpdate from "./components/extraUpdate.vue";
import ExtraSubmit from "./components/extraSubmit.vue";
import ExtraDel from "./components/extraDel.vue";
import MainNode from "./components/MainNode.vue";
import CompanyStatus from "@/components1/status/companyStatus.vue";
import {
  getCompanyEvents,
  createEvent,
  delEvent,
  updateEvent,
  submitEvent,
  getOpEvent,
  updateOpEvent,
  evaluateOpEvent,
} from "@/api1/events.js";
export default {
  components: {
    Header,
    Extra,
    MainNode,
    ExtraUpdate,
    ExtraDel,
    ExtraSubmit,
    CompanyStatus,
  },
  data() {
    return {
      products: [],
      origin: {},
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      modal: false,
    };
  },
  computed: {
    activeKey() {
      return this.products
        .filter((item) => item.mode === "update" || item.ID !== "0")
        .map((item) => item.ID);
    },
    disabled() {
      return (
        (this.isManager && this.origin.AuditStatus !== 1) ||
        (!this.isManager && this.origin.AuditStatus === 1)
      );
    },
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getCompanyEvents();
  },
  methods: {
    // 获取大事记审核详情
    getOpEvent() {
      getOpEvent(this.id).then(({ data }) => {
        const rows = [data];
        this.origin = data;
        const products = rows.map((item) => {
          return {
            ...item.Detail.Project,
            ID: item.ID,
            AuditStatus: item.AuditStatus,
            mode: "display",
          };
        });

        this.products = products;
      });
    },
    // 获取当前公司大事记
    getCompanyEvents() {
      if (this.id && this.isManager) {
        this.getOpEvent();
      } else {
        getCompanyEvents().then(({ data }) => {
          const rows = data.rows || [];
          const products = rows.map((item) => {
            return {
              ...item.Detail.Project,
              ID: item.ID,
              AuditStatus: item.AuditStatus,
              mode: "display",
            };
          });
          products.push({
            Title: "数字化转型大事记",
            ID: "0",
            mode: "display",
          });
          this.products = products;
        });
      }
    },
    // 删除数字化大事记
    delProduct(data) {
      this.delEvent(data.ID);
    },
    // 提交大事记
    submitProduct(data) {
      this.submitEvent(data.ID);
    },
    // 修改数字化大事记
    updateProduct(data) {
      this.$set(data, "mode", "update");
    },
    // 保存数字化大事记
    saveProduct(validate) {
      validate().then((res) => {
        const temp = {
          Type: 1,
          Project: res,
        };
        if (res.ID && res.ID !== "0") {
          this.updateEvent(res.ID, temp);
        } else {
          this.createEvent(temp);
        }
      });
    },
    // 取消保存数字化大事记
    cancelProduct(data) {
      this.$set(data, "mode", "display");
    },
    // 创建大事记
    createEvent(data) {
      createEvent(data).then(() => {
        this.$message.success("创建成功");
        this.getCompanyEvents();
      });
    },
    // 更新大事记
    updateEvent(id, data) {
      if (this.id && this.isManager) {
        updateOpEvent(id, data).then(() => {
          this.$message.success("更新成功");
          this.getCompanyEvents();
        });
      } else {
        updateEvent(id, data).then(() => {
          this.$message.success("更新成功");
          this.getCompanyEvents();
        });
      }
    },
    // 删除大事记
    delEvent(id) {
      delEvent(id).then(() => {
        this.$message.success("删除成功");
        this.getCompanyEvents();
      });
    },
    // 提交大事件
    submitEvent(id) {
      submitEvent(id).then(() => {
        this.$message.success("提交成功");
        this.getCompanyEvents();
      });
    },
    // 审核
    evaluateRecoginze() {
      evaluateOpEvent(this.id, this.evaluate).then(() => {
        this.$message.success("审核成功");
        this.modal = false;
        this.getCompanyEvents(this.id);
      });
    },
  },
};
</script>