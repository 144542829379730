var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-20 bg-white"},[_c('a-collapse',{staticStyle:{"background-color":"transparent"},attrs:{"activeKey":_vm.activeKey,"bordered":false}},_vm._l((_vm.products),function(item){return _c('a-collapse-panel',{key:item.ID,staticClass:"relative",staticStyle:{"border":"none"},attrs:{"showArrow":false,"disabled":true}},[(
          (item.AuditStatus !== 1 && _vm.isManager) ||
          (item.AuditStatus === 1 && !_vm.isManager)
        )?_c('div',{staticClass:"absolute w-full h-full top-0 left-0 z-10"}):_vm._e(),_c('Header',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(item.Name || item.Title))]),_c('CompanyStatus',{attrs:{"AuditStatus":item.AuditStatus}})],1)]),_c('a-space',{attrs:{"slot":"extra"},slot:"extra"},[(item.ID !== '0' && item.AuditStatus === 0)?_c('ExtraSubmit',{nativeOn:{"click":function($event){return _vm.submitProduct(item)}}}):_vm._e(),(
            item.ID !== '0' &&
            ((item.AuditStatus === 1 && _vm.isManager) ||
              (item.AuditStatus !== 1 && !_vm.isManager))
          )?_c('ExtraUpdate',{nativeOn:{"click":function($event){return _vm.updateProduct(item)}}}):_vm._e(),(item.ID !== '0' && !_vm.isManager && item.AuditStatus !== 1)?_c('ExtraDel',{nativeOn:{"click":function($event){return _vm.delProduct(item)}}}):(item.ID === '0')?_c('Extra',{nativeOn:{"click":function($event){return _vm.updateProduct(item)}}}):_vm._e()],1),_c('MainNode',{attrs:{"mode":item.mode,"dataSource":item},scopedSlots:_vm._u([{key:"update",fn:function(ref){
          var validate = ref.validate;
return _c('a-space',{},[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.cancelProduct(item)}}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveProduct(validate)}}},[_vm._v("保存")])],1)}}],null,true)}),_c('div',{staticClass:"text-right px-20"},[(
            _vm.isManager && _vm.origin.AuditStatus === 1 && item.mode === 'display'
          )?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.modal = true}}},[_vm._v("审核")]):_vm._e()],1)],1)}),1),_c('a-modal',{attrs:{"title":"审核","okText":"审核","cancelText":"取消"},on:{"ok":_vm.evaluateRecoginze},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('a-form-model',{attrs:{"model":_vm.evaluate}},[_c('a-form-model-item',[_c('a-select',{model:{value:(_vm.evaluate.AuditStatus),callback:function ($$v) {_vm.$set(_vm.evaluate, "AuditStatus", $$v)},expression:"evaluate.AuditStatus"}},[_c('a-select-option',{attrs:{"value":2}},[_vm._v("审核通过")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v("审核失败")])],1)],1),(_vm.evaluate.AuditStatus === 3)?_c('a-form-model-item',[_c('gf-textarea',{attrs:{"required":true,"prop":"Feedback","max":300,"rows":5},model:{value:(_vm.evaluate.Feedback),callback:function ($$v) {_vm.$set(_vm.evaluate, "Feedback", $$v)},expression:"evaluate.Feedback"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }