<template>
  <span
    class="status inline-block"
    :class="{
      ing: AuditStatus === 1,
      success: AuditStatus === 2,
      fail: AuditStatus === 3,
      edit: AuditStatus === 0,
    }"
  >
    <span v-if="AuditStatus === 1">审核中</span>
    <span v-else-if="AuditStatus === 2">审核成功</span>
    <span v-else-if="AuditStatus === 3">审核失败</span>
    <span v-else-if="AuditStatus === 0">未提交审核</span>
  </span>
</template>

<script>
export default {
  props: {
    AuditStatus: Number,
  },
};
</script>

<style lang="less" scoped>
.status {
  font-size: 12px;
  height: 24px;
  padding: 0 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  color: #fff;
}
.status.fail {
  background-color: #fc2c00;
}
.status.ing {
  background-color: #008dfc;
}
.status.success {
  background-color: #1ed989;
}
.status.edit {
  background-color: #000;
}
</style>