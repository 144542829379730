<template>
  <div class="hover:bg-f9 px-20 py-10" :class="{ 'bg-f9': mode === 'update' }">
    <!-- display -->
    <div v-if="mode === 'display'" class="text-sm">
      <div class="mb-20">
        <div class="text-999 mb-10">解决问题：</div>
        <div>{{ model.Desc }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">大事记类型：</div>
        <div>{{ model.AspectsName }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">参与部门：</div>
        <div>{{ model.departmentsName }}</div>
      </div>

      <div class="mb-20">
        <div class="text-999 mb-10">时间段：</div>
        <div>
          <span>{{ model.StartName }}</span>
          <span> - </span>
          <span>{{ model.EndName }}</span>
        </div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">阶段性成果：</div>
        <div>{{ model.Result }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">合作企业：</div>
        <div>{{ model.PartnersName }}</div>
      </div>
    </div>
    <!-- update -->
    <div v-else-if="mode === 'update'">
      <a-form-model
        ref="container"
        :model="model"
        labelAlign="left"
        :colon="false"
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 15 }"
      >
        <gf-form-item
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
          prop="Name"
          label="项目名称"
        >
          <gf-re-input placeholder="请输入" v-model="model.Name"></gf-re-input>
        </gf-form-item>
        <gf-form-item
          :rules="[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },
            {
              max: 300,
              message: '超出字符限制',
              trigger: ['change', 'blur'],
            },
          ]"
          prop="Desc"
          label="解决问题"
        >
          <gf-re-textarea
            placeholder="请详细描述该项目解决了什么问题"
            :rows="5"
            :max="300"
            v-model="model.Desc"
          ></gf-re-textarea>
        </gf-form-item>
        <gf-form-item
          :rules="{
            required: true,
            message: '请选择',
            trigger: ['change', 'blur'],
          }"
          prop="Aspects"
          label="大事记类型"
        >
          <gf-custom-select
            placeholder="请选择"
            flag="DigitalAspect"
            :custom="true"
            v-model="model.Aspects"
          ></gf-custom-select>
        </gf-form-item>
        <gf-form-item
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
          prop="Departments"
          label="参与部门"
        >
          <gf-select
            mode="tags"
            placeholder="请输入"
            v-model="model.Departments"
          ></gf-select>
        </gf-form-item>
        <gf-form-item
          :rules="{
            required: true,
            validator: validator,
          }"
          prop="Time"
          label="时间段"
        >
          <a-row>
            <a-col :span="11">
              <gf-form-item
                :rules="{
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                }"
                prop="Start"
              >
                <a-month-picker
                  valueFormat="x"
                  v-model="model.Start"
                ></a-month-picker>
              </gf-form-item>
            </a-col>
            <a-col :span="11" :offset="2">
              <gf-form-item
                :rules="{
                  required: true,
                  message: '请输入',
                  trigger: ['change', 'blur'],
                }"
                prop="End"
              >
                <a-month-picker
                  valueFormat="x"
                  v-model="model.End"
                ></a-month-picker>
              </gf-form-item>
            </a-col>
          </a-row>
        </gf-form-item>
        <gf-form-item
          :rules="[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },
            {
              max: 300,
              message: '超出字符限制',
              trigger: ['change', 'blur'],
            },
          ]"
          prop="Result"
          label="阶段性成果"
        >
          <gf-re-textarea
            placeholder="请详细描述该项目取得的阶段性成果"
            :rows="5"
            :max="300"
            v-model="model.Result"
          ></gf-re-textarea>
        </gf-form-item>
        <gf-form-item
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
          prop="Partners"
          label="合作企业"
        >
          <a-select
            mode="tags"
            :filterOption="false"
            :showSearch="true"
            v-model="model.Partners"
            @search="getCompanyList"
            @popupScroll="popupScroll"
          >
            <a-select-option v-for="item in company" :key="item" :title="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </gf-form-item>
      </a-form-model>
      <div class="text-right">
        <slot name="update" v-bind="{ validate }"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getCompanyList } from "@/api/api/company";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: "display",
    },
  },
  data() {
    return {
      model: {},
      company: [],
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          console.log(valid);
          if (valid) {
            const temp = { ...this.model };
            temp.Start = new Date(+temp.Start).toISOString();
            temp.End = new Date(+temp.End).toISOString();
            temp.Partners = temp.Partners.map((item) => {
              return {
                Name: item,
              };
            });
            if (typeof temp.Aspects === "string") {
              temp.Aspects = [
                {
                  Name: temp.Aspects,
                  ID: 0,
                },
              ];
            } else {
              temp.Aspects = this.options.DigitalAspect.filter((option) => {
                return temp.Aspects === option.ID;
              });
            }

            resolve(temp);
          } else {
            reject();
          }
        });
      });
    },
    validator(rule, value, callback) {
      return callback();
    },
    getCompanyList(e) {
      this.$set(this.model, "CompanyName", "");
      if (!e) {
        this.company = [];
        return;
      }
      const params = { name: e };
      getCompanyList(params)
        .then(({ rows }) => {
          this.company = rows.map((item) => item.companyName);
        })
        .catch(() => {
          this.$message.error("搜索企业列表失败");
        });
    },
    popupScroll(e) {
      console.log(e);
    },
  },
  created() {
    const temp = { ...this.dataSource };
    if (temp.Partners) {
      temp.Partners = temp.Partners.map((item) => item.Name);
      temp.PartnersName = temp.Partners.join("、");
    }
    if (temp.Departments) {
      temp.departmentsName = temp.Departments.join("、");
    }
    if (temp.Aspects) {
      temp.AspectsName = temp.Aspects[0].Name;
      temp.Aspects = temp.Aspects[0].ID;
    }
    if (temp.Start) {
      temp.Start = moment(temp.Start);
      temp.StartName = temp.Start.format("YYYY-MM");
    }
    if (temp.End) {
      temp.End = moment(temp.End);
      temp.EndName = temp.End.format("YYYY-MM");
    }

    this.model = temp;
  },
  watch: {
    dataSource(val) {
      const temp = { ...val };
      if (temp.Partners) {
        temp.Partners = temp.Partners.map((item) => item.Name);
        temp.PartnersName = temp.Partners.join("、");
      }
      if (temp.Aspects) {
        temp.AspectsName = temp.Aspects[0].Name;
        temp.Aspects = temp.Aspects[0].ID;
      }
      if (temp.Departments) {
        temp.departmentsName = temp.Departments.join("、");
      }
      if (temp.Start) {
        temp.Start = moment(temp.Start);
        temp.StartName = temp.Start.format("YYYY-MM");
      }
      if (temp.End) {
        temp.End = moment(temp.End);
        temp.EndName = temp.End.format("YYYY-MM");
      }
      this.model = temp;
    },
  },
};
</script>